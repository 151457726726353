(function(Date) {
    'use strict';

    /**
     * Return a full date with the name of the day.
     *
     * @param {string} date
     *
     * @return {string}
     */
    Date.fullDate = function(date) {
        return moment(date, Way2web.config.format.date_carbon).format(Way2web.config.format.full_date_moment);
    };

    /**
     * Calculate the left position of a ticket since the start of the day.
     *
     * @param {string} timeStart The start time of a ticket.
     *
     * @return {string} The position in percentage.
     */
    Date.calculateStartPercentage = function(timeStart) {
        var startOfDay = moment('00:00', Way2web.config.format.time_moment).format('x');
        var ticketStart = moment(timeStart, Way2web.config.format.time_moment).format('x');

        return Date.calculatePercentage(ticketStart - startOfDay);
    };

    /**
     * Calculate the width of a ticket since the start of the ticket.
     *
     * @param {string} timeStart The start time of a ticket.
     * @param {string} timeEnd The end time of a ticket.
     * @param {string} changeoverTime The changeover time of a ticket.
     *
     * @return {string} The position in percentage.
     */
    Date.calculateBetweenPercentage = function(timeStart, timeEnd, changeoverTime) {
        var ticketStart = moment(timeStart, Way2web.config.format.time_moment);
        var ticketEnd = moment(timeEnd, Way2web.config.format.time_moment);
        var ticketWidth = 0;
        var ticketTime = ticketEnd.diff(ticketStart);
        var ticketChangeoverTime = 0;

        if(changeoverTime) {
            ticketChangeoverTime = Date.minutesToMiliseconds(changeoverTime);
        }

        ticketWidth = moment(ticketTime + ticketChangeoverTime).format('x');

        return Date.calculatePercentage(ticketWidth);
    };

    /**
     * Calculate the percentage of a time based on a day.
     *
     * @param {string} time A moment object.
     *
     * @return {string} The position in percentage.
     */
    Date.calculatePercentage = function(time) {
        var day = moment.duration(Way2web.config.constants.hoursPerday, 'hours').asMilliseconds();

        return ((time / day) * 100) + '%';
    };

    /**
     * Calculate a time from a index. If index = 24 and slotsPerHour is 4
     * the number presenting the time is: 6.25 meaning: 06:15.
     *
     * @param {integer} index Iteration index of the total hours of the day.
     * @param {integer} slotsPerHour The total of slots per hour.
     *
     * @return {object} The calculated time as a momemt object.
     */
    Date.numberToTime = function(index, slotsPerHour) {
        var timeNumber = index / slotsPerHour;
        var time = timeNumber.toString().split('.');
        var hour = time[0];
        var decimalMinutes = time[1] || 0;
        var minutes = 0;

        if (decimalMinutes == 25) {
            minutes = 15;
        }

        if (decimalMinutes == 5) {
            minutes = 30;
        }

        if (decimalMinutes == 75) {
            minutes = 45;
        }

        return moment({
            hour:    hour,
            minutes: minutes
        });
    };

    /**
     * Convert an amount of minutes to an amount of miliseconds.
     *
     * @param  {number} minutes
     *
     * @return {int}
     */
    Date.minutesToMiliseconds = function(minutes) {
        return minutes * 60 * 1000;
    };
})(window.Way2web.Date = window.Way2web.Date || {});
