(function(MaterialCheckbox) {
    'use strict';

    var selectors = {
        siteRows:          '.js-site-row',
        materialCheckbox:  '.js-material-checkbox',
        notAvailableBlock: '.js-not-available-block'
    };

    var globals = {
        selectedMaterials: []
    };

    /**
    * Initialize the material checkboxes on the ticket form.
    */
    MaterialCheckbox.init = function() {
        $(selectors.materialCheckbox).on('change', MaterialCheckbox.change);
        $(selectors.materialCheckbox).change();
    };

    /**
     * Function called when a material checkbox changes.
     */
    MaterialCheckbox.change = function() {
        var checkbox = $(this);
        var arrayIndex = globals.selectedMaterials.indexOf(parseInt(checkbox.val()));

        if (checkbox.prop('checked')) {
            globals.selectedMaterials.pushIfNotExists(parseInt(checkbox.val()));
        } else if (arrayIndex >= 0) {
            globals.selectedMaterials.splice(arrayIndex, 1);
        }

        if (globals.selectedMaterials.length > 0) {
            $(selectors.siteRows).find('input').prop('disabled', true);
            $(selectors.siteRows).find(selectors.notAvailableBlock).show();

            $(selectors.siteRows).each(MaterialCheckbox.enableRelatedMaterials);
        } else {
            $(selectors.siteRows).find('input').prop('disabled', false);
            $(selectors.siteRows).find(selectors.notAvailableBlock).hide();
        }
    };

    /**
     * Enable the site if it's related to a selected material.
     */
    MaterialCheckbox.enableRelatedMaterials = function() {
        var siteField = $(this);
        var relatedMaterials = siteField.data('material');
        var index;

        for (index = 0; index < globals.selectedMaterials.length; index++) {
            if (relatedMaterials.indexOf(globals.selectedMaterials[index]) >= 0) {
                siteField.find('input').prop('disabled', false);
                siteField.find(selectors.notAvailableBlock).hide();
            }
        }
    };
})(window.Way2web.Ticket.MaterialCheckbox = window.Way2web.Ticket.MaterialCheckbox || {});
