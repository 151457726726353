(function(Api) {
    'use strict';

    /**
     * Show a failed network request error to the user.
     *
     * @param {object} error The JavaScript error message;
     */
    Api.error = function(error) {
        // @TODO: Add a nice way to show errors to the user.

        // eslint-disable-next-line
        console.error(
            'There has been a problem with your axios get operation: ',
            error.message
        );
    };
})(window.Way2web.Api = window.Way2web.Api || {});
