(function(RepeatSelect) {
    'use strict';

    var selectors = {
        repeatSelect: '.js-repeat-select',
        repeatRow:    '.repeat-row'
    };

    /**
     * Initialize the repeat end select list on the ticket form.
     */
    RepeatSelect.init = function() {
        $(selectors.repeatSelect).on('change', RepeatSelect.change);
        $(selectors.repeatSelect).change();
    };

    /**
     * Function called when the repeat end select list changes.
     */
    RepeatSelect.change = function() {
        var select = $(this);

        if (!select.val()) {
            $(selectors.repeatRow).addClass('hidden');
        } else {
            $(selectors.repeatRow).removeClass('hidden');
        }
    };
})(window.Way2web.Ticket.RepeatSelect = window.Way2web.Ticket.RepeatSelect || {});
