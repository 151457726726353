(function(Components) {
    'use strict';

    /**
     * @TODO: make dynamic using translation lib.
     */
    /* eslint-disable max-len */
    var pikadayTranslations = {
        previousMonth: 'Vorige maand',
        nextMonth:     'Volgende maand',
        months:        [
            'januari',
            'februari',
            'maart',
            'april',
            'mei',
            'juni',
            'juli',
            'augustus',
            'september',
            'oktober',
            'november',
            'december'
        ],
        weekdays:      ['zondag', 'maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag'],
        weekdaysShort: ['zon', 'maa', 'din', 'woe', 'don', 'vri', 'zat']
    };
    /* eslint-enable */

    /**
     * Initialize the date picker.
     */
    Components.initDatePicker = function() {
        var element = $(this);
        var minDate = false;
        let options = {
            field:          element[0],
            format:         element.data('date-format'),
            showWeekNumber: true,
            i18n:           pikadayTranslations,
            minDate:        minDate
        };

        if (element.data('pikaday-min-date')) {
            options.minDate = new Date(element.data('pikaday-min-date'));
        }
        if (!element.data('with-sundays')) {
            options.disableDayFn = date => date.getDay() === 0;
        }

        /**
         * Pikaday is a vanilla JS package, so use the vanilla JS dom element
         * from the jQuery element object.
         */
        new Pikaday(options);
    };

    /**
     * Initialize the week picker.
     */
    Components.initWeekPicker = function() {
        var element = $(this);

        /**
         * Pikaday is a vanilla JS package, so use the vanilla JS dom element
         * from the jQuery element object.
         */
        new Pikaday({
            field:         element[0],
            pickWholeWeek: true,
            onSelect:      function(date) {
                var sundayDate = date.getDate() - date.getDay();
                var sunday = new Date(date.setDate(sundayDate));
                var saturday = new Date(date.setDate(sundayDate + 6));

                element[0].value = sunday.toLocaleDateString() + ' - ' + saturday.toLocaleDateString();
            },
            showWeekNumber: true,
            i18n:           pikadayTranslations
        });
    };

    /**
     * Initialize the color palette picker
     */
    Components.initColorPalettePicker = function() {
        var element = $(this);

        element.spectrum({
            preferredFormat: 'hex',
            showPaletteOnly: true,
            showPalette:     true,
            showInput:       false,
            allowEmpty:      false
        });
    };

    /**
     * Initialize the confirm function.
     */
    Components.initConfirm = function() {
        $('.js-confirm').confirm({
            // TODO: js translations
            title:         'Weet je het zeker dat je dit wilt verwijderen?',
            text:          '',
            confirmButton: 'Ok',
            cancelButton:  'Annuleren'
        });
    };

    /**
     * Initialize the select2 function, for nice select elements.
     */
    Components.initSelect2 = function() {
        var select = $(this);
        var placeholder = select.data('placeholder');

        if (typeof placeholder === 'undefined' || placeholder === '') {
            throw 'A data-placeholder value is needed on the select2 element';
        }

        select.select2({
            allowClear:  true,
            placeholder: placeholder,
            theme:       'bootstrap',
            width:       null
        });
    };

    /**
     * Toggles form fields based on the selected value.
     *
     * Add the class 'js-select-toggle' to the select, 'js-form-select-field' to a possible toggle field.
     *
     * Add the 'data-select-values=""' data attribute with in it, the value(s) it needs to be visible for.
     * For example: <div class="form group | js-select-toggle" data-select-values='["admin","company-admin"]'>
     */
    Components.initSelectFieldToggle = function() {
        var elemSelect = $('.js-select-toggle');
        var possibleFields = $('.js-select-toggle-field');

        var processFields = function() {
            var selectedValue = elemSelect.val();

            possibleFields.hide();

            if (selectedValue.length == 0) {
                return;
            }

            possibleFields.each(function() {
                var field = $(this);
                var fieldToggleValues = field.data('select-values');

                if (fieldToggleValues.indexOf(selectedValue) !== -1) {
                    field.show();
                }
            });
        };

        if (elemSelect.length == 0) {
            return;
        }

        elemSelect.on('change', function() {
            processFields();
        });

        processFields();
    };

    Components.initWYSIWYG = function() {
        var toolbar = [
            ['style', ['bold', 'italic', 'underline', 'clear']],
            ['font', ['strikethrough', 'superscript', 'subscript']],
            ['para', ['ul', 'ol', 'paragraph']],
            ['insert', ['link']],
            ['misc', ['undo', 'redo']],
            ['codeview', ['codeview']]
        ];

        Way2web.summernote = [];
        $('.js-wysiwyg').each(function() {
            Way2web.summernote.push(
                new W2Summernote()
                    .element($(this))
                    .toolbar(toolbar)
                    .init()
            );
        });
    };

    /**
     * Toggle some target elements when a boolean value is chosen.
     */
    Components.initSelectToggle = function() {
        $('.js-select-toggle').on('change', Way2web.Components.selectToggle);
    };

    /**
     * Toggle the elements by the boolean value.
     */
    Components.selectToggle = function() {
        var selected = $(this)
            .find(':selected')
            .val();
        var gateSelect = $('.js-select-toggle-target');

        if (selected == 'true') {
            gateSelect.removeClass('hidden');
        } else {
            gateSelect.addClass('hidden');
        }
    };

    /**
     * Prefill a target element with data in the data-prefill-data attribute
     * on the selected option in the select box.
     */
    Components.prefillFromSelect = function() {
        var selector = $('.js-prefill-selector');
        var target = $('.js-prefill-target');
        var reset = $('.js-prefill-reset');

        reset.on('click', function() {
            target.val('');
        });

        selector.on('change', function() {
            var selectedOption = $(this).find(':selected');
            var prefillData = $(selectedOption).data('prefill-data');

            target.val(prefillData);
        });
    };

    Components.initGoToDate = function() {
        $('.js-go-to-date').on('change', Way2web.Components.goToDate);
    };

    Components.goToDate = function() {
        var value = $(this).val();

        if (typeof value === 'undefined' || value === '') {
            return;
        }

        window.location.href = $(this).attr('href') + '/' + value;
    };

    Components.postOnChange = function() {
        $('.js-post-on-change').on('change', Way2web.Components.postForm);
    };

    Components.postForm = function() {
        $(this)
            .closest('form')
            .submit();
    };

    Components.initAxios = function() {
        window.axios.defaults.headers.common = {
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRF-TOKEN':     document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        };
    };

    Components.initWeatherCode = function() {
        $(this).on('change', Way2web.Components.processWeatherCode);
    };

    Components.processWeatherCode = function() {
        var element = $(this);
        var embedCode = element.val();
        var typeField = element.closest('.js-weather-widget-form').find('.js-weather-widget-type');
        var types = typeField.data('types');
        var locationField = element.closest('.js-weather-widget-form').find('.js-weather-location-code');
        var searchValue = '/weerwidgets/';
        var index = embedCode.indexOf(searchValue);
        var parts = embedCode.substring(index + searchValue.length).split('/');
        var type = parts[0];
        var locationCode = parts[1];

        typeField.val(types[type]);
        typeField.change();

        locationField.val(locationCode);
    };
})((window.Way2web.Components = window.Way2web.Components || {}));
