/**
 * Handles the date navigation.
 *
 * @param {object} element
 *
 * @return {object}
 */
window.DateNavigation = function(element) {
    'use strict';

    var data = {
        date:              element.data('date'),
        component:         element.data('component'),
        propertyReference: element.data('property-reference'),
        url:               element.data('url')
    };

    var elements = {
        previous: {
            class:    '.js-date-previous',
            action:   'click',
            function: previous
        },
        next: {
            class:    '.js-date-next',
            action:   'click',
            function: next
        },
        now: {
            class:    '.js-date-now',
            action:   'click',
            function: now
        },
        picker: {
            class:    '.js-datepicker',
            action:   'change',
            function: picker
        },
        show: {
            class: '.js-date-show'
        },
        well: {
            class: '.js-well'
        }
    };

    /**
     * Start the date navigation.
     *
     * @return {object}
     */
    function init() {
        $.each(elements, attachActions);

        return this;
    }

    /**
     * Attach actions to classes.
     *
     * @param {string} elementFunction
     * @param {object} elementObject
     */
    function attachActions(elementFunction, elementObject) {
        if (elementObject.action && elementObject.class && elementObject.function) {
            element
                .find(elementObject.class)
                .on(elementObject.action, elementObject.function);
        }
    }

    /**
     * Get the previous date.
     *
     * @param {object} event
     */
    function previous(event) {
        var steps = $(this).data('day-steps');

        event.preventDefault();

        data.date = moment(data.date).subtract(steps, 'days').format(Way2web.config.format.date_carbon);

        work();
    }

    /**
     * Get a next date.
     *
     * @param {object} event
     */
    function next(event) {
        var steps = $(this).data('day-steps');

        event.preventDefault();

        data.date = moment(data.date).add(steps, 'days').format(Way2web.config.format.date_carbon);

        work();
    }

    /**
     * Get the current date.
     *
     * @param {object} event
     */
    function now(event) {
        event.preventDefault();

        data.date = moment().format(Way2web.config.format.date_carbon);

        work();
    }

    /**
     * Get the date from the picker.
     *
     * @param {object} event
     */
    function picker(event) {
        var value = $(this).val();
        var date;

        event.preventDefault();

        if (!value) {
            return;
        }

        date = moment(value, Way2web.config.format.date_moment);

        if (date.isValid()) {
            data.date = date.format(Way2web.config.format.date_carbon);
            work();
        }
    }

    /**
     * Do all the work when the date is changed.
     */
    function work() {
        setUrl();
        sendDataToVue();
        show();
    }

    /**
     * Set the url with the history api.
     */
    function setUrl() {
        if (data.url) {
            history.pushState(
                {
                    date: data.date
                },
                false,
                data.url + '/' + data.date
            );
        }
    }

    /**
     * Send the data to a ref of a component in vue.
     */
    function sendDataToVue() {
        var hasDate = false;

        if (typeof data.date !== 'undefined' && data.date !== '') {
            hasDate = true;
        }

        if (hasDate && typeof window.app.$refs[data.component][data.propertyReference] != 'undefined') {
            window.app.$refs[data.component][data.propertyReference] = data.date;
        }
    }

    /**
     * Show the date.
     */
    function show() {
        var showElement = element.find(elements.show.class);
        var wellElement = $(elements.well.class);
        var date = moment(data.date).format(Way2web.config.format.date_moment);

        if (showElement) {
            showElement.text(date);
        }

        if (wellElement) {
            wellElement.removeClass('hidden');
        }
    }

    return {
        data: data,
        init: init
    };
};
