/**
 * License checker.
 */
class LicenseChecker {
    /**
     * Set the element.
     *
     * @param {object} element
     */
    constructor(element) {
        this.cancel;
        this.element = element;

        if (!this.element) {
            return;
        }

        const input = this.element.querySelector('input#license_plate');

        if (input.value) {
            this.check(input.value);
        }

        input.addEventListener('input', event => {
            this.check(event.target.value);
        });
    }

    /**
     * Handle the input.
     *
     * @param {string} input
     */
    check(input) {
        const licensePlate = LicensePlate.create(input).sanitizedInput;
        let self = this;

        if (this.cancel) {
            this.cancel('License plate check is canceled, because there was a new input: "' + licensePlate + '"');
        }

        this.resetClasses();

        if (licensePlate.length == 0) {
            return;
        }

        if (licensePlate.length != 6) {
            this.setFailClass();

            return;
        }

        this.setWaitClasses();
        this.getRdwData(licensePlate)
            .then(({
                data
            }) => {
                self.setClasses(data.result);
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    console.log(error.message);
                }
            });
    }

    /**
     * Get the RDW status.
     *
     * @param {string} licensePlate
     *
     * @return {Promise}
     */
    getRdwData(licensePlate) {
        let self = this;

        return axios
            .post(
                route('license-plate.check'),
                {
                    text: licensePlate
                },
                {
                    headers: {
                        // If we leave this out, the token is not included in the first call when editing a ticket with
                        // a saved license plate, so it fails with a 419.
                        'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                    },
                    cancelToken: new axios.CancelToken(cancel => {
                        self.cancel = cancel;
                    })
                }
            );
    }

    /**
     * Set the classes by state.
     *
     * @param {bool} isValid
     */
    setClasses(isValid) {
        this.resetClasses();

        if (isValid) {
            this.setOkClass();
        } else {
            this.setFailClass();
        }
    }

    /**
     * Set the classes if the input is ok.
     */
    setOkClass() {
        this.element.classList.add('license-ok');
    }

    /**
     * Set the classes if the input isnt ok.
     */
    setFailClass() {
        this.element.classList.add('license-fail');
    }

    /**
     * Set the wait indicator class.
     */
    setWaitClasses() {
        this.element.classList.add('license-wait');
    }

    /**
     * Reset all classes.
     */
    resetClasses() {
        this.element.classList.remove('license-ok', 'license-fail', 'license-wait');
    }
}
/**
 * A single license plate.
 */
class LicensePlate {
    /**
     * Set the sanitized and original input/
     *
     * @param {string} sanitizedInput
     * @param {string} input
     */
    constructor(sanitizedInput, input) {
        this.sanitizedInput = sanitizedInput;
        this.input = input;
    }


    /**
     * Cleanup the license plate text.
     *
     * @param {string} input
     *
     * @return {LicensePlate}
     */
    static create(input) {
        const sanitizedInput = input.toUpperCase().replace(/[^0-9A-Z]/g, '');

        return new LicensePlate(sanitizedInput, input);
    }
}
