(function(Ticket) {
    'use strict';

    /**
     * Initialize the ticket form.
     */
    Ticket.init = function() {
        Ticket.SiteCheckbox.init();
        Ticket.MaterialCheckbox.init();
        Ticket.RepeatSelect.init();
        Ticket.RepeatEndSelect.init();
    };
})(window.Way2web.Ticket = window.Way2web.Ticket || {});
