(function(Table) {
    'use strict';

    /**
     * Add datatables to the page with ajax.
     */

    // Object with the elements for the datatable.
    Table.elements = {
        tableSelector: '.js-datatable'
    };

    // The datatables will be stored in this array.
    Table.items = [];

    // Find the elements to connect the datatables.
    Table.init = function() {
        $(Table.elements.tableSelector).each(Table.find);
    };

    // Attach the DataTable core to the elements.
    Table.find = function() {
        var item = new DataTable(
            $(this),
            {
                language: Way2web.Helpers.getLanguage(),
                dom:
                    // eslint-disable-next-line max-len
                    '<"datatables-actions datatables-actions-end"B>tr<"datatables-actions"<"datatables-length"li>p><"clear">',
                buttons: [
                    {
                        extend:        'csvHtml5',
                        text:          'CSV',
                        className:     'btn-default',
                        exportOptions: {
                            columns: ':visible'
                        }
                    },
                    {
                        extend:        'excelHtml5',
                        text:          'Excel',
                        className:     'btn-default',
                        exportOptions: {
                            columns: ':visible'
                        }
                    },
                    {
                        extend:        'pdfHtml5',
                        text:          'PDF',
                        className:     'btn-default',
                        exportOptions: {
                            columns: ':visible'
                        }
                    },
                    {
                        extend:    'colvis',
                        className: 'btn-default',
                        text:      '<i class="fa fa-columns"></i> Columns'
                    }
                ],
                perPage: 25
            },
            {},
            Table.Translations
        );

        item.init();
        Table.items.push(item);
    };
})((window.Way2web.Table = window.Way2web.Table || {}));
