(function(Way2web) {
    'use strict';

    /**
     * Call function if the dom is ready.
     */
    Way2web.onDomReady = function() {
        Way2web.setCSRFToken();
        Way2web.Table.init();

        Way2web.Components.initAxios();
        Way2web.Components.initConfirm();
        Way2web.Components.initSelectFieldToggle();
        Way2web.Components.initWYSIWYG();
        Way2web.Components.initSelectToggle();
        Way2web.Components.initGoToDate();
        Way2web.Components.postOnChange();
        Way2web.Components.prefillFromSelect();
        $('.js-datepicker').each(Way2web.Components.initDatePicker);
        $('.js-weekpicker').each(Way2web.Components.initWeekPicker);
        $('.js-color-palette-picker').each(Way2web.Components.initColorPalettePicker);
        $('.js-select2').each(Way2web.Components.initSelect2);
        $('.js-weather-embed-code').each(Way2web.Components.initWeatherCode);
        $('.js-print').click(Way2web.printPage);
        Way2web.dateNavigation();
        Way2web.Tabs.init();
        Way2web.Logs.init();
        Way2web.Clones.init();
        Way2web.MomentLocale.init();

        Way2web.Ticket.init();

        Way2web.form = new W2Form().init();

        Way2web.LicenseChecker = new LicenseChecker(document.querySelector('.js-license-check'));
    };

    /**
     * Attach the date navigations.
     */
    Way2web.dateNavigation = function() {
        Way2web.dateNavigations = [];
        $('.js-date-navigation').each(function() {
            Way2web.dateNavigations.push(new DateNavigation($(this)).init());
        });
    };

    /**
     * Set the CSRF token in ajax calls.
     */
    Way2web.setCSRFToken = function() {
        var token = $('meta[name="csrf-token"]').attr('content');

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': token
            }
        });
    };

    /**
     * Allow the user to print the current page by clicking on a button.
     */
    Way2web.printPage = function() {
        window.print();
    };
})((window.Way2web = window.Way2web || {}));

/**
 * A wrapper around the trans() function, so we can be consistent between the front-end and the back-end code.
 *
 * @param {string} string
 * @param {object} args
 *
 * @return {string}
 */
window.trans = function(string, args) {
    return Lang.get(string, args);
};

/**
 * A wrapper around the Lang.choice() function, so we can be consistent between the front-end and the back-end code.
 *
 * @param  {string} string
 * @param  {number} amount
 * @param  {object} args
 *
 * @return {string}
 */
/* eslint-disable camelcase */
window.trans_choice = function(string, amount, args) {
    return Lang.choice(string, amount, args);
};
/* eslint-enable */

$(document).ready(window.Way2web.onDomReady);
