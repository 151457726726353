(function(Translations) {
    'use strict';

    /**
     * Get the Datatable translations.
     *
     * @return {object}
     */
    Translations.get = function() {
        var groupName = 'datatable.';
        return {
            'oAria': {
                'sSortAscending':  'Oplopend',
                'sSortDescending': 'Aflopend',
            },
            'oPaginate': {
                'sFirst':    'Eerste',
                'sLast': 'Laatste',
                'sNext': 'Volgende',
                'sPrevious': 'Vorige'
            },
            'sEmptyTable':     'Geen resultaten aanwezig in de tabel',
            'sInfo':           '_START_ tot _END_ van _TOTAL_ resultaten',
            'sInfoEmpty':      'Geen resultaten om weer te geven',
            'sInfoFiltered':   ' (gefilterd uit _MAX_ resultaten)',
            'sInfoPostFix':    '',
            'sInfoThousands':  '.',
            'sLengthMenu':     '_MENU_ resultaten weergeven',
            'sLoadingRecords': 'Een moment geduld aub - bezig met laden...',
            'sProcessing':     'Bezig...',
            'sSearch':         'Zoeken:',
            'sZeroRecords':    'Geen resultaten gevonden',
            'all':             'Alles',
            'columns':         'Kolommen',
        };
    };
})(window.Way2web.Table.Translations = window.Way2web.Table.Translations || {});
