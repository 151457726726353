(function(SiteCheckbox) {
    'use strict';

    var selectors = {
        materialRows:          '.js-material-row',
        siteCheckbox:          '.js-site-checkbox',
        notAvailableBlock:     '.js-not-available-block',
        dynamicSiteParameters: '.js-dynamic-site-parameters'
    };

    var globals = {
        selectedSites: []
    };

    /**
     * Initialize the site checkboxes on the ticket form.
     */
    SiteCheckbox.init = function() {
        $(selectors.siteCheckbox).on('change', SiteCheckbox.change);
        $(selectors.siteCheckbox).change();
    };

    /**
     * Function called when a site checkbox changes.
     */
    SiteCheckbox.change = function() {
        var checkbox = $(this);
        var arrayIndex = globals.selectedSites.indexOf(parseInt(checkbox.val()));

        if (checkbox.prop('checked')) {
            globals.selectedSites.pushIfNotExists(parseInt(checkbox.val()));
        } else if (arrayIndex >= 0) {
            globals.selectedSites.splice(arrayIndex, 1);
        }

        if (globals.selectedSites.length > 0) {
            $(selectors.materialRows).find('input').prop('disabled', true);
            $(selectors.materialRows).find(selectors.notAvailableBlock).show();

            $(selectors.materialRows).each(SiteCheckbox.enableRelatedMaterials);
            $(selectors.dynamicSiteParameters).show();
        } else {
            $(selectors.materialRows).find('input').prop('disabled', false);
            $(selectors.materialRows).find(selectors.notAvailableBlock).hide();
            $(selectors.dynamicSiteParameters).hide();
        }
    };

    /**
     * Enable the material if it's related to a selected site.
     */
    SiteCheckbox.enableRelatedMaterials = function() {
        var materialField = $(this);
        var relatedSites = materialField.data('sites');
        var index;

        for (index = 0; index < globals.selectedSites.length; index++) {
            if (relatedSites.indexOf(globals.selectedSites[index]) >= 0) {
                materialField.find('input').prop('disabled', false);
                materialField.find(selectors.notAvailableBlock).hide();
            }
        }
    };
})(window.Way2web.Ticket.SiteCheckbox = window.Way2web.Ticket.SiteCheckbox || {});
