(function(Way2web) {
    'use strict';

    /**
     * Settings for the JavaScript functions.
     *
     * @type {object}
     */
    Way2web.config = {
        classes: {
            success: 'has-success',
            error:   'has-error',
            hidden:  'hidden',
            active:  'is-active'
        },
        tabPrefix: 'w2w_tab_',
        format:    {
            'date_carbon':      'YYYY-MM-DD',
            'date_moment':      'DD-MM-YYYY',
            'time_moment':      'HH:mm',
            'datetime_moment':  'DD-MM-YYYY HH:mm',
            'full_date_moment': 'dddd DD-MM-YYYY'
        },
        constants: {
            interval:     (1000 * 60),
            hoursPerday:  24,
            slotsPerHour: 4
        }
    };
})(window.Way2web = window.Way2web || {});
