(function(Clones) {
    'use strict';

    // Object with the elements for the clones.
    Clones.selector = '.js-clone';

    Clones.items = [];

    // Search for the elements to clone.
    Clones.init = function() {
        $(Clones.selector).each(Clones.find);
    };

    // Attach to the clone function.
    Clones.find = function() {
        Clones.items.push(
            new Clone(
                $(this),
                {
                    toggle:      '.js-add-clone-button',
                    remove:      '.js-clone-delete',
                    counter:     '.js-clone-count',
                    template:    '.js-clone-template',
                    target:      '.js-clone-target',
                    removeClone: '.js-clone-remove-cloned'
                },
                {
                    hidden: Way2web.config.classes.hidden
                },
                {
                    clone: {
                        date:    Clones.setDatePicker,
                        select2: Clones.setSelect2,
                        widget:  Clones.setWidget
                    }
                }
            )
                .init()
        );
    };

    Clones.setDatePicker = function($clone) {
        $clone.find('.js-datepicker').each(Way2web.Components.initDatePicker);
    };

    Clones.setSelect2 = function($clone) {
        $clone.find('.js-select2-clone').each(Way2web.Components.initSelect2);
    };

    Clones.setWidget = function($clone) {
        $clone.find('.js-weather-embed-code').each(Way2web.Components.initWeatherCode);
    };
})(window.Way2web.Clones = window.Way2web.Clones || {});
