(function(RepeatEndSelect) {
    'use strict';

    var selectors = {
        repeatEndSelect:                  '.js-repeat-end-select',
        repeatEndDateWrapper:             '.repeat-end-date-wrapper',
        repeatEndAttemptedTicketsWrapper: '.repeat-end-attempted-tickets-wrapper'
    };

    /**
     * Initialize the repeat end select list on the ticket form.
     */
    RepeatEndSelect.init = function() {
        $(selectors.repeatEndSelect).on('change', RepeatEndSelect.change);
        $(selectors.repeatEndSelect).change();
    };

    RepeatEndSelect.hideAll = function() {
        $(selectors.repeatEndDateWrapper).addClass('hidden');
        $(selectors.repeatEndAttemptedTicketsWrapper).addClass('hidden');
    };

    /**
     * Function called when the repeat end select list changes.
     */
    RepeatEndSelect.change = function() {
        var select = $(this);

        RepeatEndSelect.hideAll();

        if ('date' === select.val()) {
            $(selectors.repeatEndDateWrapper).removeClass('hidden');
        } else if ('attempted_tickets' === select.val()) {
            $(selectors.repeatEndAttemptedTicketsWrapper).removeClass('hidden');
        }
    };
})(window.Way2web.Ticket.RepeatEndSelect = window.Way2web.Ticket.RepeatEndSelect || {});
